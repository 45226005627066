<template>
  <v-dialog
    :value="user.data.rolle == 'Vorstand'"
    :content-class="$vuetify.breakpoint.mdAndDown ? undefined : 'rounded-xl'"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Person suchen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="mt-4" v-if="!searched">
        <v-col cols="10" lg="8" class="px-2">
          <v-text-field
            v-model="t.vorname"
            rounded
            filled
            label="Vorname"
          ></v-text-field>
          <v-text-field
            v-model="t.nachname"
            rounded
            filled
            label="Nachname"
          ></v-text-field>
          <v-btn
            class="my-4"
            block
            rounded
            large
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="search()"
            :loading="searching"
            :disabled="!t.vorname || !t.nachname"
          >
            <v-icon class="mr-2">mdi-magnify</v-icon>
            Suchen
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-4" v-if="searched">
        <v-col cols="10">
          <h2>Suchergebnisse:</h2>
        </v-col>
        <v-col cols="10" v-if="search_result.length == 0">
          <h4>Keine Person gefunden</h4>
        </v-col>
        <v-col cols="10" v-for="(result, index) in search_result" :key="index">
          <v-card
            class="rounded-xl pa-4"
            :color="template.colors.blocks"
            :light="!template.isDark(template.colors.blocks)"
            :dark="template.isDark(template.colors.blocks)"
            :to="`/team/${result.team.id}/mitglied/${result.mitglied.id}`"
          >
            <v-row>
              <v-col cols="auto" align-self="center">
                <v-icon large>
                  mdi-account
                </v-icon>
              </v-col>
              <v-col align-self="center">
                <h3 class="mb-0">
                  {{ result.mitglied.vorname }} {{ result.mitglied.name }}
                </h3>
                <small>
                  {{ result.team.name }}
                  {{
                    result.mitglied.rolle ? ' - ' + result.mitglied.rolle : ''
                  }}
                </small>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="10">
          <v-btn
            large
            :color="template.colors.primary"
            rounded
            @click="searched = false"
          >
            Neue Suche
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Person-suchen',
  data() {
    return {
      t: {
        vorname: '',
        nachname: '',
      },
      search_result: [],
      searching: false,
      searched: false,
    }
  },
  mounted() {
    this.t.verein = this.user.data.verein
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    async search() {
      this.searching = true
      this.search_result = []
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .get()
        .then(async (snap) => {
          await snap.forEach(async (team) => {
            var t = team.data()
            t.id = team.id
            await team.ref
              .collection('Mitglieder')
              .where('vorname', '==', this.t.vorname)
              .where('name', '==', this.t.nachname)
              .get()
              .then(async (teamsnap) => {
                await teamsnap.forEach((member) => {
                  var m = member.data()
                  m.id = member.id
                  this.search_result.push({
                    mitglied: m,
                    team: t,
                  })
                })
              })
          })
          this.searching = false
          this.searched = true
        })
    },
  },
}
</script>
