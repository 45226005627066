<template>
  <div>
    <v-row justify="center" class="pt-5 mt-2">
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>
          mdi-account-group
        </v-icon>
      </v-col>
      <v-col align-self="center" class="text-uppercase">
        <h2
          class="white--text font-weight-bold"
          v-if="user.data.rolle == 'Trainer' && user.data.team.length > 1"
        >
          Meine Teams
        </h2>
        <h2
          class="white--text font-weight-bold"
          v-if="user.data.rolle == 'Trainer' && user.data.team.length == 1"
        >
          Mein Team
        </h2>
        <h2
          class="white--text font-weight-bold"
          v-if="user.data.rolle == 'Vorstand'"
        >
          Teams
        </h2>
      </v-col>
      <v-col
        cols="auto"
        align-self="center"
        v-if="user.data.rolle == 'Vorstand'"
      >
        <v-btn
          :text="$vuetify.breakpoint.mdAndUp"
          :fab="$vuetify.breakpoint.smAndDown"
          @click="newteam = true"
        >
          <v-icon>mdi-plus</v-icon>
          <span class="ml-2" v-if="$vuetify.breakpoint.mdAndUp">
            Neues Team
          </span>
        </v-btn>
        <v-btn
          :text="$vuetify.breakpoint.mdAndUp"
          :fab="$vuetify.breakpoint.smAndDown"
          @click="search_person = true"
        >
          <v-icon>mdi-magnify</v-icon>
          <span class="ml-2" v-if="$vuetify.breakpoint.mdAndUp">
            Person suchen
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="pb-0"
        v-if="user.data.abteilungen.length > 0 && user.data.rolle == 'Vorstand'"
        align-self="center"
      >
        <v-select
          v-model="filter"
          @change="filtering()"
          :items="user.data.abteilungen"
          item-text="name"
          item-value="name"
          chips
          label="Filter"
          prepend-icon="mdi-filter"
          multiple
          background-color="rgba(0,0,0,0)"
          solo
          small
          hide-details
          flat
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pb-0" align-self="center">
        <v-btn
          text
          small
          @click="
            sortierung == 'Name (absteigend)'
              ? (sortierung = 'Name (aufsteigend)')
              : (sortierung = 'Name (absteigend)')
            sortieren()
          "
        >
          <v-icon class="mr-2">
            {{
              sortierung == 'Name (absteigend)'
                ? 'mdi-sort-descending'
                : 'mdi-sort-ascending'
            }}
          </v-icon>
          Name
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      style="max-height: 80vh; overflow-y: scroll;"
      v-if="teams.length == 0"
    >
      <v-col cols="12">
        <h3>
          {{
            user.data.rolle == 'Vorstand'
              ? 'Du hast derzeit noch kein Team angelegt. Bitte erstelle ein Team.'
              : 'Du bist keinem Team als Trainer zugewiesen. Dein Vorstand muss dich noch einem Team zuweisen.'
          }}
        </h3>
      </v-col>
      <v-col cols="12" v-if="user.data.rolle == 'Vorstand'">
        <cardbutton
          :title="'Team erstellen'"
          :icon="'mdi-plus'"
          :textcolor="template.colors.inline_primary_text"
          :bgcolor="template.colors.primary"
          upper
          @click="newteam = true"
        />
      </v-col>
    </v-row>
    <v-row
      justify="center"
      style="max-height: 80vh; overflow-y: scroll; overflow-x: hidden;"
    >
      <v-col cols="12" class="px-2">
        <cardbutton
          :title="t.name"
          :vereinslogo="user.data.verein.logo"
          :verein="
            t.abteilung
              ? t.abteilung + ' | ' + user.data.verein.name
              : user.data.verein.name
          "
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          chev
          v-for="(t, index) in teams"
          :key="index"
          :to="`/team/${t.id}`"
        />
        <CreateTeam v-if="newteam" v-on:closing="newteam = false" />
        <SearchPerson
          v-if="search_person"
          v-on:closing="search_person = false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CreateTeam from './create'
import SearchPerson from './search_member'
import cardbutton from '../../../elements/cards/card-button'
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'

export default {
  name: 'Start',
  data() {
    return {
      newteam: false,
      search_person: false,
      teams: [],
      sortierung: 'Name (aufsteigend)',
      filter: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.user.data.rolle == 'Vorstand') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .orderBy('name')
        .onSnapshot(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              if (
                !this.filter ||
                this.filter.length == 0 ||
                this.filter.includes(doc.data().abteilung)
              ) {
                this.teams.push(doc.data())
                var index = this.teams.length - 1
                this.teams[index].id = doc.id
              }
            })
          },
          (error) => {
            console.log(error)
          },
        )
    } else if (this.user.data.rolle == 'Trainer') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .where('name', 'in', this.user.data.team)
        .onSnapshot(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              this.teams.push(doc.data())
              var index = this.teams.length - 1
              this.teams[index].id = doc.id
            })
          },
          (error) => {
            console.log(error)
          },
        )
    }
  },
  components: {
    CreateTeam,
    SearchPerson,
    cardbutton,
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    filtering() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .orderBy('name')
        .get()
        .then(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              if (
                !this.filter ||
                this.filter.length == 0 ||
                this.filter.includes(doc.data().abteilung)
              ) {
                this.teams.push(doc.data())
                var index = this.teams.length - 1
                this.teams[index].id = doc.id
              }
            })
          },
          (error) => {
            console.log(error)
          },
        )
      this.sortieren()
    },
    sortieren() {
      function compareNameASC(a, b) {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.name > b.name) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        this.teams.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.teams.sort(compareNameDESC)
      }
    },
  },
}
</script>
